// scss-docs-start color-functions
// Tint a color: mix a color with white
@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

:root {
  --bc-yellow: #{$bc-yellow};
  --bc-red: #{$bc-red};
  --bc-purple: #{$bc-purple};
  --bc-blue: #{$bc-blue};
  --bc-green: #{$bc-green};
  --bc-grey: #{$bc-grey};
  --bc-black: #{$bc-black};
  --bc-white: #{$bc-white};

  --bc-background-blue: #{tint-color($bc-blue, 70%)};
  --bc-background-blue: #{tint-color($bc-blue, 90%)};

  --bc-border-grey: #{tint-color($bc-black, 70%)};
  --bc-background-grey: #{tint-color($bc-black, 90%)};

  --bc-highlight: var(--bc-blue);
  --bc-hover: var(--bc-purple);
}

@media (min-width: 1200px) {
  body:not(.full-width) .container {
    max-width: var(--bs-breakpoint-lg);
  }
}

.form-control {
  font-weight: var(--bs-body-font-weight);
  &::placeholder {
    opacity: 0.5;
  }
}

:target {
  scroll-margin-top: 7rem;
}

body {
  -webkit-font-smoothing: antialiased;
}

@for $i from 1 through 7 {
  .fw-#{$i}00 {
    font-weight: #{$i}00;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Exo', 'Ubuntu Mono', monospace;

  b {
    text-transform: uppercase;
  }

  b, strong {
    color: var(--bc-highlight);
    font-weight: unset;
  }

}

h1 span.beyondcron {
  color: var(--bc-blue);
  font-size: 50px;
}

@media (min-width: 768px) {
  h1 span.beyondcron {
    color: var(--bc-blue);
    font-size: 70px;
  }
}
a,
a.code {
  color: var(--bc-highlight);
  text-decoration: none;

  &:visited {
    color: var(--bc-highlight);
  }

  &:hover {
    color: var(--bc-hover);
  }
}

.has-tooltip {
  border-bottom: 1px dotted var(--bc-border-grey);
  cursor: help;
}

.subtitle {
  color: var(--bc-highlight);
  font-weight: var(--bc-bold-font-weight);
  text-transform: uppercase;
}

.section {
  padding: 1rem 0;

  &.bg-light {
    border-top: 1px solid var(--bc-border-grey);
    border-bottom: 1px solid var(--bc-border-grey);

    & + & {
      border-top: none;
    }
  }

  &.bg-black {
    border-top: 1px solid var(--bc-border-grey);
  }
}

.show-more {
  &:before {
    color: var(--bs-body-color);
    font-family: "Material Symbols Outlined";
    font-size: 250%;
    content: "add_circle";
  }
  &:hover:before {
    color: var(--bc-highlight);
  }
}

.see-more {
  &:after {
    color: var(--bs-body-color);
    font-family: "Material Symbols Outlined";
    font-size: 150%;
    content: "read_more";
  }

  &:hover {
    &:before {
      color: var(--bc-highlight);
    }
  }
}

.bc-shadow {
  box-shadow: rgb(0, 0, 0, 0.25) 0px 2px 15px 5px;
}

a.bc-shadow {
  img {
    box-shadow: rgb(0, 0, 0, 0.25) 0px 2px 15px 5px;

    &:hover {
      box-shadow: tint-color($bc-blue, 50%) 0px 2px 10px 5px;
    }
  }
}

.bc-rounded {
  border-radius: 0.4rem;
}

.item {
  @extend .bg-light;

  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: var(--bc-border-grey) 0px 0px 0px 1px, rgba(255, 255, 255, 1.0) 0px 0px 0px 2px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    flex-grow: 1;
  }

  &.link:hover {
    border-color: var(--bc-black);
    box-shadow: var(--bc-highlight) 0px 0px 2px 2px;

    .see-more:before {
      color: var(--bc-highlight);
    }
  }

  :last-child,
  p:last-of-type {
    margin-bottom: 0;
  }

  .see-more {
    text-align: right;
    align-self: flex-end;
  }
}

.bg-light .item {
  background-color: var(--bc-white) !important;
}

.beyondcron::before {
  content: "BeyondCron";
  text-transform: none;
}

//button,
a,
button,
div {
  &.btn {
    --bs-btn-color: var(--bc-white);
    --bs-btn-font-weight: var(--bs-body-font-weight);

    &:visited {
      color: var(--bc-white);
    }
  }

  &.btn-primary {
    --bc-btn-bg: var(--bc-blue);
  }
  &.btn-success {
    --bs-btn-bg: var(--bc-green);
    --bs-btn-border-color: var(--bc-green);
  }
  &.btn-danger {
    --bs-btn-bg: var(--bc-red);
    --bs-btn-border-color: var(--bc-red);
  }

  &.btn-outline-primary {
    color: var(--bc-blue);
    &:visited {
      color: var(--bc-blue);
    }
    &:hover {
      color: var(--bc-white);
    }
  }
  &.btn-outline-success {
    color: var(--bc-green);
    &:visited {
      color: var(--bc-green);
    }
    &:hover {
      color: var(--bc-white);
    }
  }
}

label[class*="btn-outline-"] {
  background-color: var(--bc-white);
}

.form-select {
  font-weight: var(--bs-body-font-weight);
}

.bg-dark {
  .navbar-toggler {
    border-style: none;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1.0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }

  .dropdown-menu {
    box-shadow: var(--bc-white) 0 0 1px 0;
  }

  .nav-link {
    color: var(--bc-white);
    border-radius: var(--bs-border-radius);

    &:hover {
      background-color: var(--bs-tertiary-color);
    }

    &:focus-visible {
      box-shadow: none;
    }
  }

  a.dropdown-item {
    font-weight: var(--bs-body-font-weight);

    &:hover {
      background-color: var(--bs-tertiary-color);
    }

    &:visited {
      color: inherit;
    }
  }
}

.dropdown {
  a:focus-visible {
    outline-style: none;
  }
}

.smaller {
  font-size: 80%;
}

.page-footer {
  color: var(--bs-secondary-color);
}

.terms {
  font-weight: var(--bc-bold-font-weight);
  color: var(--bs-tertiary-color);

  a {
    &:link,
    &:visited {
      color: inherit;
    }

    &:hover {
      color: var(--bc-hover);
    }
  }
}

.repo,
.eap,
.deprecated {
  display: none;
}

.quiet {
  a:link,
  a:visited {
    color: inherit;
  }

  a:hover {
    color: var(--bc-hover);
  }
}

.breadcrumb {
  text-transform: uppercase;
  font-size: 80%;
}

.cmd {
  background: var(--bc-background-grey);
}

.out {
  background: white;
}

.cmd,
.out {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  line-height: 1.2em;
  margin: 1em;
  border: 1px solid var(--bc-border-grey);
  border-radius: var(--bs-border-radius);
  padding: 0.5em 1em;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.open {
    border-bottom: 0;
    border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
    margin-bottom: 0;
  }

  &.cont {
    border-bottom: 0;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  &.close {
    border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
    margin-top: 0;
  }

  &.comment {
    color: var(--bc-grey);
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  /* preserve whitespace */
  &.ws {
    white-space: pre;
  }

  b {
    font-weight: var(--bs-body-font-weight);
    color: var(--bc-grey);
  }

  strong {
    color: var(--bc-blue);
  }
}

.announce {
  margin: 0.5em 2em 0;

  &::before {
    display: block;
    content: '“';
    color: #{$bc-blue};
    font-size: 4rem;
    font-family: 'Exo', 'Ubuntu Mono', monospace;
    position: relative;
    line-height: 0;
    left: -2rem;
    top: 1.4rem;
  }
}

.download {
  background-color: var(--bs-gray-200);
  padding: #{($spacer * 0.25)};

  span {
    margin: 0 #{($spacer * 0.25)};
  }

  & + p {
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: 80%;
  }
}

.accordion {
  .accordion-item {
    border-bottom: none;
    //box-shadow: none;

    .accordion-body {
      padding-top: 0;
    }

    .accordion-button {
      align-items: baseline;
      box-shadow: none !important;
      border-bottom: solid 2px #{$bc-white};
      background-color: var(--bc-white);
      color: var(--bc-blue);

      &:hover {
        background-color: var(--bs-gray-200);
      }

      .date {
        font-family:  var(--bs-font-sans-serif);
        font-size: var(--bc-font-size-base);
        font-weight: var(--bs-body-font-weight);
        margin-left: #{($spacer * 0.25)};
      }
    }
  }
}
.selector {
  label:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: var(--bs-border-radius);
  }

  label:last-of-type {
    border-top-right-radius: var(--bs-border-radius);
    border-bottom-right-radius: 0;
  }
}

h3 {
  &.step {
    line-height: 1.4;
  }

  span.step {
    display: inline-block;
    margin-left: -60px;
    margin-right: 10px;
    border-radius: 50%;
    background: #{$bc-blue};
    color: white;
    width: 40px;
    height: 40px;
    text-align: center;
  }
}

ul {
  padding-left: 1.5rem;
  list-style: disc;
}

code {
  color: inherit;
  border: 1px solid var(--bc-border-grey);
  background-color: var(--bc-background-grey);
  font-family: var(--bs-font-monospace);
  font-size: inherit;
  padding: 0.125rem 0.3125rem 0.0625rem;
  white-space: nowrap;
}

.code {
  @extend code;
}

.alert {
  :last-child {
    margin-bottom: 0;
  }

  .out.comment {
    background: transparent;
    color: inherit;
    font: inherit;
  }
}

.note::before {
  content: 'Note: ';
  color: #616161;
  font-family: Exo, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.see::before {
  content: 'See: ';
  color: #616161;
  font-family: Exo, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

table {
  margin-bottom: 1rem;

  &.args {
    margin: 0 1.5rem 1.5rem 1.5rem;

    th {
      border-bottom: 1px solid;
      padding: 0.5rem 0.625rem 0.4rem;
    }

    td {
      padding: 0.25rem 0.5rem;
      vertical-align: top;
    }
  }

  &.alternatives {
    padding-bottom: 2rem;
  }

  &.alternatives,
  &.properties {
    border: none;

    thead {
      text-align: left;
      vertical-align: bottom;
      position: sticky;
      top: 0;
      z-index: 1;

      a {
        color: inherit;
      }

      th {
        padding: 0.5rem 0.625rem 0.625rem;
        font-weight: bolder;
      }
    }

    tbody {
      border: none;
      border-bottom: 1px solid var(--bc-border-grey);

      tr {
        border-top: 1px solid var(--bc-border-grey);

        &.type {
          &:hover {
            background-color: #{tint-color($bc-blue, 80%)};
          }
        }
      }

      th, td {
        padding: 0.5rem 0.625rem 0.625rem;
      }

      th {
        text-align: left;
      }

      td {
        text-align: center;

        .fa {
          color: var(--bc-blue);
          font-size: 120%;
        }
      }

      .section,
      .type {
        th, td {
          padding: 0.25rem 0.625rem 0.25rem;
        }
      }

      .type {

        th {
          font-weight: inherit;
          padding-left: 1.5rem;
          white-space: nowrap;
        }
      }
    }
  }

  &.alternatives {

    border-collapse: separate;
    border-spacing: 0;

    .blue {
      background-color: var(--bc-background-blue);
    }

    .grey {
      background-color: var(--bc-background-grey);
    }

    thead {
      background-color: white;

      th {
        border-bottom: 2px solid var(--bc-border-grey);
      }
    }

    tbody, tr, th, td {
      background-color: transparent;
    }

    tbody {
      tr.section > th,
      tr.section > td {
        border-top: 1px solid var(--bc-border-grey);
      }

      tr:last-child {
        th, td {
          border-bottom: 1px solid var(--bc-border-grey);
        }
      }

      th, td {
        vertical-align: top;

        .material-symbols-outlined {
          padding: 0 0.2rem;
        }
      }

      td {
        p.product {
          margin-bottom: 0;
        }

        span.amber {
          color: var(--bc-yellow);
        }

        span.green {
          color: var(--bc-green);
        }

        span.red {
          color: var(--bc-red);
        }

        span.partial {
          content: "~";
          top: -0.5rem;
        }

        &.note {
          text-align: left;
          width: 100%;

          &::before {
            content: unset;
          }

          //p + p {
          //  margin-top: 0.5rem;
          //}
        }
      }
    }
  }
}

div.option-b, p.option-b,
div.option-c, p.option-c,
div.option-d, p.option-d,
div.option-e, p.option-e {
  display: none;
}

.no-focus {
  pointer-events: none;
}

.store {
  label {
    font-weight: bolder;
  }

  .section {
    padding-top: 0;
  }
}

label {
  &.required:after {
    content: "\2022";
    color: var(--bc-blue);
    font-weight: bold;
    margin-left: 0.25em;
  }
}

.faq-q {
  @extend h4;
}

.bc-job-flow {
  --lumo-font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  font: var(--lumo-font-family);
  fill: var(--lumo-body-text-color);

  .bc-job {
    rect {
      fill: white;
      stroke: var(--bc-black);
      stroke-width: 1px;
      border-radius: 5px;
    }

    &.bc-disabled rect {
      stroke: var(--lumo-contrast-30pct);
      stroke-width: 2px;
    }

    &.bc-missing rect {
      stroke: var(--lumo-contrast-30pct);
      stroke-width: 2px;
      stroke-dasharray: 5, 5;
    }

    &.bc-selected rect {
      stroke: var(--bc-blue);
      stroke-width: 3px;
    }
  }

  .bc-icon {
    font-family: "Material Symbols Outlined";
    font-weight: 400;
    fill: var(--lumo-secondary-text-color);
    transform: translate(0, 0.14em);
    //transform-origin: 0.5em 0.4em;
  }

  .bc-icon-job {
    font-family: "Material Symbols Filled";
  }

  .bc-icon-bypass {
    display: none;
  }

  .bc-bypass {
    .bc-icon-bypass {
      display: inline;
    }

    .bc-icon-job {
      display: none;
    }
  }

  .bc-success :is(.bc-icon-job, .bc-icon-bypass) {
    fill: var(--bc-green);
  }

  .bc-active :is(.bc-icon-job, .bc-icon-bypass) {
    fill: var(--bc-blue);
    animation: bc-spin 2s linear infinite;
  }

  :is(.bc-error, .bc-missed, .bc-killed, .bc-exception, .bc-failed, .bc-error) :is(.bc-icon-job, .bc-icon-bypass) {
    fill: var(--bc-red);
  }

  :is(.bc-condition, .bc-trigger, .bc-arrow) {
    fill: none;
    stroke: var(--lumo-secondary-text-color);
    stroke-width: 2px;
  }

  .bc-trigger {
    stroke-dasharray: 4;
  }

  :is(.bc-condition, .bc-trigger, .bc-arrow).bc-success {
    stroke: var(--bc-green);
  }

  :is(.bc-condition, .bc-trigger, .bc-arrow):is(.bc-error, .bc-missed, .bc-killed, .bc-exception, .bc-failed, .bc-error) {
    stroke: var(--bc-red);
  }

  use.bc-state-active {
    color: var(--bc-blue);
  }

  svg.bc-icon use {
    fill: var(--bc-blue);
    transform-origin: 0.5em;
    animation: bc-spin 2s linear infinite;
  }
}

.youtube-video {
  aspect-ratio: 16 / 9;
  border-bottom: 1px solid black;
  border-radius: 0.5vw;
  width: 100%;
}

.embed-container {
  position: relative;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 0.5vw;
  width: 100%;

  &::after {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}